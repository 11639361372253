/* --------------------------------------------------------------------------------------
   add-recipient-popup.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const addRecipientPopupComponent = {
  template: require("./add-recipient-popup.html"),
  bindings: {
    display: "=",
    ok: "&",
    cancel: "&"
  },
  controllerAs: "vm",
  controller: class addRecipientPopupController {
    /* @ngInject */
    constructor(commonService, homeService) {
      this.commonService = commonService
      this.homeService = homeService
    }

    $onInit() {
      this.screenSize = this.commonService.getScreenSize()
      this.resetForm()
    }

    resetForm() {
      this.homeService.recipientsBackup = angular.copy(
        this.homeService.recipients
      )
      this.disableOk = true
      this.editing = false
      this.emailRecipient = ""
      this.emailRecipientRequired = false
      this.emailRecipientInvalid = false
      this.commonService.updateView()
    }

    onOk() {
      this.homeService.recipientsBackup = angular.copy(
        this.homeService.recipients
      )
      if (!this.emailRecipient) {
        this.emailRecipientRequired = true
        this.commonService.updateView()
        this.showEnterEmailAddressBanner()
        return
      }
      if (!this.validateEmail(this.emailRecipient)) {
        this.emailRecipientInvalid = true
        this.commonService.updateView()
        return
      }
      if (
        this.homeService.recipients.findIndex(
          (recipient) => recipient === this.emailRecipient
        ) >= 0
      ) {
        this.showEmailAlreadyExistsBanner()
        return
      }
      this.homeService.addNew(this.emailRecipient)
      this.emailRecipient = ""
      this.disableOk = true
      this.commonService.updateView()
      this.updateEmailRecipientsPopupTableScroll()
      this.ok()
      document.activeElement.blur()

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
      angular.element('#add-recipient-popup').xrxpopup('close');
    }

    onCancel() {
      document.activeElement.blur()
      this.resetForm()
      this.cancel()

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
      angular.element('#add-recipient-popup').xrxpopup('close');
    }

    showEnterEmailAddressBanner() {
      angular.element("#enter-email-address-banner").xrxbanner("call")
    }

    showEmailAlreadyExistsBanner() {
      angular.element("#email-already-exists-banner").xrxbanner("call")
    }

    emailRecipientClick() {
      this.editing = true
      this.commonService.updateView()
    }

    emailRecipientClose() {
      this.editing = false
      this.commonService.updateView()
    }

    emailRecipientChanged() {
      this.emailRecipientRequired = false
      this.emailRecipientInvalid = false
      this.emailRecipientBackup = angular.copy(this.emailRecipient)
      if (this.emailRecipient === "") {
        this.emailRecipientRequired = true
      }
      if (this.emailRecipientRequired || this.emailRecipientInvalid) {
        this.disableOk = true
      } else {
        this.disableOk = false
      }
      this.commonService.updateView()
    }

    validateEmail(email) {
      const re = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

      return re.test(email)
    }

    emailRecipientCanceled() {
      this.emailRecipient = this.emailRecipientBackup
      this.commonService.updateView()
    }

    onBeforeClosePopup() {
      this.emailRecipient = null
      this.emailRecipientBackup = null
      this.emailRecipientRequired = false

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
    }

    updateEmailRecipientsPopupTableScroll() {
      setTimeout(() => {
        angular.element("#recipients-wrapper").xrxscrollable({ scroll: true })
        if (this.hasScroll()) {
          angular.element("#recipients-table-wrapper").removeClass("no-scroll")
          angular.element("#recipients-wrapper").xrxscrollable({ scroll: true })
          angular.element("#recipients-wrapper .shadow").removeClass("hide")
        } else {
          angular.element("#recipients-table-wrapper").addClass("no-scroll")
          angular.element("#recipients-wrapper").xrxscrollable("destroy")
          angular.element("#recipients-wrapper .shadow").addClass("hide")
        }
        this.commonService.updateView()
      }, 100)
    }

    hasScroll() {
      return this.screenSize === 7
        ? this.homeService.recipients.length * 69 > 308
        : this.homeService.recipients.length * 63 > 420
    }
  }
}
