/* --------------------------------------------------------------------------------------
   listSelector.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

/*
  TODO LIST:
  Merge model and default id, then assign the model using the original field names.
*/ 

export const listSelectorComponent = {
    template: require('./listSelector.html'),
    controllerAs: 'vm',
    controller: class listSelectorCtrl {
      /* @ngInject*/
      constructor(apiClientService, commonService) {
        this.apiClientService = apiClientService
        this.commonService = commonService
      }
      async $onInit() {
        this.items = await this.getItems()
        this.ngModel = this.selected = this.getDefault()
        this.commonService.updateView()
      }
      getColumnId(side) {
        return 'list-selector-' + this.fieldName.replace(' ','-').toLowerCase() + '-' + side
      }
      $onChanges(change) {
        this.showRequired = change.validateOn.currentValue && !this.ngModel 
      }
      async getItems() {
        const result = await this.apiClientService.get(this.itemsEndpoint)
        return result.map(i => ({ key: i[this.keyField], value: i[this.valueField] }))
      }
      getDefault() {
        if (this.ngModel && this.ngModel.key) {
          return this.items.find(i => i.key == this.ngModel.key)
        } else if (this.defaultId){          
          return this.items.find(i => i.key == this.defaultId)
        }
      }
      openPopup() {
        if (this.type == 'popup')
          this.select = true
        this.commonService.updateView()
      }
      async assignModel() {      
        //don't remove await
        await this.commonService.updateView()
        this.ngModel = angular.copy(this.selected)
        if (this.ngModel)  
          this.showRequired = false
        this.commonService.updateView()
      }
    },
    bindings: {
      fieldName: '@',      
      placeholder: '@',
      ngModel: '=',
      itemsEndpoint: '@',
      keyField: '@',
      valueField: '@',
      defaultId: '<',
      type: '@',
      validateOn: '<'      
    }
  }
  