/* --------------------------------------------------------------------------------------
   xeroxSSO.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const xeroxSSOComponent = {
  template:
    '<auto-post-form options="vm.autopostModel" ng-if="vm.autopostModel"></auto-post-form>',
  controllerAs: "vm",
  controller: class xeroxSSOComponentCtrl {
    /* @ngInject */
    constructor(
      commonService,
      sessionDataService,
      apiClientService,
      shellDataBagService,
      xeroxSSOService,
      authService,
      $stateParams,
      $state
    ) {
      this.commonService = commonService
      this.sessionDataService = sessionDataService
      this.apiClientService = apiClientService
      this.shellDataBagService = shellDataBagService
      this.xeroxSSOService = xeroxSSOService
      this.authService = authService
      this.$stateParams = $stateParams
      this.$state = $state
    }
    async $onInit() {
      if (this.$stateParams.logout) {
        return this.onLogout()
      }
      const routeParams = this.getRouteParams()
      if (routeParams.route == "authentication") this.$state.go("forcelogin")

      if (routeParams.route == "error") {
        this.onError()
      }

      if (routeParams.route == "main") {
        const token = this.getAuthToken(routeParams.token)
        if (token) {
          if (!token.invalid) {
            let authToken = token.token
            if (
              moment(token.expiration).isBefore(moment().add(45, "minutes"))
            ) {
              try {
                authToken = await this.apiClientService.post("/auth", token)
              } catch (error) {
                this.$state.go("forcelogin")
              }
            }
            this.shellValues = this.shellDataBagService.getData()
            this.apiClientService.setTokenAuthHeader(
              authToken,
              this.shellValues.appId,
              this.shellValues.deviceSN,
              this.shellValues.apiKey
            )
            this.sessionDataService.usingSSO = true
            this.authService.clearAuthCookie()
            this.$state.go("landing")
          } else {
            this.$state.go("forcelogin")
          }
        }
      }
    }
    getRouteParams() {
      const params = new URLSearchParams(window.location.search)
      const encodedToken = params.get("token")
      const decodedToken = atob(encodedToken)

      return {
        route: params.get("route"),
        token: encodedToken ? angular.fromJson(decodedToken) : null,
        sessionId: params.get("sessionId"),
        updatedAccessToken: params.get("updatedAccessToken"),
        updatePath: params.get("updatePath")
      }
    }
    getAuthToken(token) {
      return angular.fromJson(token.largeData)
    }
    async onLogout() {
      this.autopostModel = await this.xeroxSSOService.getAutopostModel({
        invalid: true
      })
      return this.commonService.updateView()
    }
    onError() {
      const localToken = this.authService.getAuthCookie()
      if (localToken) {
        this.apiClientService.setTokenAuthHeader(localToken.token)
        this.$state.go("landing")
      } else {
        this.$state.go("forcelogin")
      }
    }
  }
}
