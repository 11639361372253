/* --------------------------------------------------------------------------------------
apiClient.service.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export class apiClientService {
  /* @ngInject */
  constructor(appSettings, authService, $http, $window, $state, shellDataBagService) {
    this.appSettings = appSettings
    this.authService = authService
    this.$http = $http
    this.$window = $window
    this.$state = $state
    this.shellValues = shellDataBagService.getData()
  }

  async get(uri) {
    const endpoint = this.getApiUrl() + uri
    const result = await this.$http.get(endpoint, { headers: this.headers })
    return result.data
  }

  async post(uri, postData) {
    const endpoint = this.getApiUrl() + uri
    const result = await this.$http.post(endpoint, postData, {
      headers: this.headers
    })
    return result.data
  }

  async delete(uri) {
    const endpoint = this.getApiUrl() + uri
    const result = await this.$http.delete(endpoint, { headers: this.headers })
    return result.data
  }

  async ssoPost(uri, postData) {
    const endpoint = this.getSsoUrl() + uri
    const result = await this.$http.post(endpoint, postData, {
      headers: this.headers
    })
    return result.data
  }

  async put(uri, postData) {
    const endpoint = this.getApiUrl() + uri
    const result = await this.$http.put(endpoint, postData, {
      headers: this.headers
    })
    return result.data
  }

  getApiUrl() {
    if (!this.apiUrl) this.setApiUrl()
    return this.apiUrl
  }

  getSsoUrl() {
    if (!this.apiUrl) this.setApiUrl()
    return this.apiUrl + "/ssoapi"
  }

  setApiUrl() {
    const host = this.$window.location.host
    console.log("host is: " + host)

    if (host.includes("localhost")) this.isLocal = true

    if (this.isLocal && this.appSettings.localhostApi == "local")
      this.apiUrl = this.appSettings.devApis.localhost
    if (
      host.includes("-dev") ||
      (this.isLocal && this.appSettings.localhostApi == "dev")
    )
      this.apiUrl = this.appSettings.devApis.dev
    else if (
      host.includes("-test") ||
      (this.isLocal && this.appSettings.localhostApi == "test")
    )
      this.apiUrl = this.appSettings.devApis.test
    else if (
      host.includes("-staging") ||
      (this.isLocal && this.appSettings.localhostApi == "staging")
    )
      this.apiUrl = this.appSettings.devApis.staging

    if (!this.apiUrl) this.apiUrl = this.appSettings.api

    this.setApiKeyHeader(this.shellValues.apiKey)

    console.log("api is: " + this.apiUrl)
  }

  logout() {
    this.headers["auth"] = null
    this.isLoggedIn = false
    this.authService.clearAuthCookie()
  }

  setTokenAuthHeader(token, appId, deviceSN) {
    if (!this.headers) this.headers = {}
    this.headers["auth"] = token
    this.headers["bexrx-application-id"] = appId
    this.headers["bexrx-device-sn"] = deviceSN
    this.isLoggedIn = true
  }

  setApiKeyHeader(apiKey) {
    if (!this.headers) this.headers = {}
    this.headers["x-api-key"] = apiKey
  }
}
