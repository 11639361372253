/* --------------------------------------------------------------------------------------
   preview-popup.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

import angular from "angular"

export const previewPopupComponent = {
  template: require("./preview-popup.html"),
  bindings: {
    display: "="
  },
  controllerAs: "vm",
  controller: class previewPopupController {
    /* @ngInject */
    constructor(commonService, homeService, errorService, $translate, $state) {
      this.commonService = commonService
      this.homeService = homeService
      this.errorService = errorService
      this.$translate = $translate
      this.$state = $state
    }

    async $onInit() {
      this.screenSize = this.commonService.getScreenSize()
      this.boxHeight = this.screenSize == 7 ? 342 : 452
      this.loading = false;
      this.commonService.updateView()

      setTimeout(() => {
        this.refreshBoundingBox()
        this.changeSummarizationLengthSelector()
      }, 100)

      const discardLabel = await this.$translate("DISCARD")
      const keepSummaryLabel = await this.$translate("KEEP_SUMMARY")

      this.confirmDiscardAlertButtons = [
        {
          alertIcon: "glyphicon-close",
          text: discardLabel,
          click: this.close
        },
        {
          alertIcon: "glyphicon-checkmark",
          text: keepSummaryLabel,
          click: this.closeConfirmDiscardAlert
        }
      ]
    }

    onCheckCancelSummary() {
      this.confirmDiscard = true
      this.commonService.updateView()
    }

    closeConfirmDiscardAlert() {
      if (angular.element("#confirm-discard-alert")) {
        angular.element("#confirm-discard-alert").xrxalert("close")
      }
    }

    onCloseConfirmDiscardAlert() {
      this.confirmDiscard = false
      this.commonService.updateView()
    }

    close() {
      if (angular.element("#preview-popup"))
        angular.element("#preview-popup").xrxpopup("close")
    }

    onClose() {
      this.display = false
      this.commonService.updateView()
    }

    onCancel() {
      this.display = false
      this.commonService.updateView()
    }

    async onContinue() {
      try {
        this.close()

        // Print if print settings is on
        if (this.homeService.printResults) {
          this.homeService.isPrinting = true
          this.homeService.printingLabel = "PRINTING"
          this.commonService.updateView()
        }

        // Email if email settings is on
        if (this.homeService.emailResults) {
          this.homeService.isPrinting = true
          this.homeService.printingLabel = "EMAILING"
          this.commonService.updateView()
        }

        if (this.homeService.printResults && this.homeService.emailResults) {
          this.homeService.isPrinting = true
          this.homeService.printingLabel = "EMAIL_PRINT"
          this.commonService.updateView()
        }

        const blobUrl = await this.homeService.sendSummarizationEmail()

        if (this.homeService.printResults) {
          await this.homeService.print(blobUrl.url)
          this.homeService.isPrinting = false
          this.commonService.updateView()
        }
        this.homeService.isPrinting = false
        this.commonService.updateView()
      } catch (error) {
        this.homeService.isPrinting = false
        this.showCustomError(error)
        this.commonService.updateView()
      }
    }

    showCustomError(error) {
      this.homeService.isPrinting = false
      this.homeService.errorType = this.errorService.getErrorByStatusCode(
        error.status
      )
      this.homeService.onErrorClose = () => {
        this.errorService.getErrorCallback(this.homeService.errorType)()
      }
    }

    selectLengthOption(value) {
      this.loading = true;
      this.commonService.updateView();
      this.homeService.summarizationLengthIndex = value.index
      this.homeService.summarizationLengthText = value.key
      this.homeService.summarizationLengthText =
        this.homeService.summarizationLengthOptions[value.index].key
      this.homeService.summarizationLengthValue =
        this.homeService.summarizationLengthOptions[value.index].value
      this.homeService.setSummaryDetails();
      setTimeout(() => {
        this.refreshBoundingBox()
        this.changeSummarizationLengthSelector()
      }, 100)
      //debounce the loading animation
      setTimeout(() => {
         this.loading = false;
       this.commonService.updateView()
      }, 500)
   
    }

    changeSummarizationLengthSelector() {
      angular
        .element("#preview-length-selector-menu li")
        .removeClass("ui-state-focus ui-state-selected")
      angular
        .element("#preview-length-selector-menu li")
        .eq(this.homeService.summarizationLengthIndex)
        .addClass("ui-state-selected")
      angular
        .element("#preview-settings-button")
        .xrxbutton("setText", this.homeService.summarizationLengthText)
      this.commonService.updateView()
    }

    refreshBoundingBox() {
      angular.element("#preview-data-summary").xrxscrollable({
        scroll: true,
        height: this.screenSize == 7 ? 342 : 452,
        width: this.screenSize == 7 ? 788 : 1006
      })

      this.checkScroll()
    }

    checkScroll() {
      const clientHeight = angular
        .element("#preview-data-summary .scroller")
        .height()

      if (this.boxHeight - 32 > clientHeight) {
        angular.element("#preview-data-summary .shadow").addClass("no-shadow")
      }
    }
  }
}
