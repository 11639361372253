/* --------------------------------------------------------------------------------------
   xwButton.directive.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

/*eslint semi: ["error", "never"]*/
 export class xwButton {
  /*@ngInject*/
  constructor (appSettings) {
    this.restrict = 'A'
    this.appSettings = appSettings
    this.scope = {
      off: '<'
    }
  }
  link(scope, elem, attrs) {
    let init = false
    const element = elem.first()
    setTimeout(() => {
      element.xrxbutton(this.getOptions(attrs, scope))
      init = true
    })

    attrs.$observe('label', val => {
      if (init) {
        element.xrxbutton('option','label', val)
      }
    })

    scope.$watch('off', val => {
      if (init)
        element.xrxbutton({disabled: val})
    })
  }
  getOptions(attrs, scope) {
    return {
      text: !attrs.hasOwnProperty('noText'),
      label: attrs.label,
      widgetSize: attrs.widgetSize? attrs.widgetSize : 'xrx-medium',
      width: attrs.width,
      height: attrs.height,
      icons: attrs.glyph? { glyph: 'glyphicon-' + attrs.glyph } : {},
      theme: attrs.theme? attrs.theme : this.appSettings.primaryColor,
      themePrimary: attrs.hasOwnProperty('themePrimary'),
      disabled: scope.off
    }
  }
  static directiveFactory(appSettings) {
    xwButton.instance = new xwButton(appSettings)
    return xwButton.instance
  }
}
