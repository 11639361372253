/* --------------------------------------------------------------------------------------
   errorMessage.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const errorMessageComponent = {
  template: `
    <div
      xw-alert
      display="vm.errorType"
      ng-class="(!vm.errorType.keys || vm.errorType.keys.length == 0) ? 'default-alert-height' : ''"
      on-close="vm.onClose()"
      close-button
      ng-if="!vm.errorType.continueButton && !vm.errorType.continueAndCancelButtons && !vm.errorType.cancelTryAgainButtons"
    >
      <p class="white-text error-title">{{ vm.errorType.titleKey | translate }}</p>
      <p class="error-description" ng-if="vm.errorType.errorCode">
        <span translate>ERROR</span>: {{ vm.errorType.errorCode }}
      </p>
      <div
        class="error-description"
        ng-if="vm.errorType.keys && vm.errorType.keys.length > 0"
      >
        <div ng-repeat="key in vm.errorType.keys">{{ key | translate }}</div>
      </div>
    </div>
    <div
      xw-alert
      display="vm.errorType"
      ng-class="(!vm.errorType.keys || vm.errorType.keys.length == 0) ? 'default-alert-height' : ''"
      on-accepted="vm.onClose()"
      continue-button
      ng-if="vm.errorType.continueButton"
    >
      <p class="white-text error-title">{{ vm.errorType.titleKey | translate }}</p>
      <p class="error-description" ng-if="vm.errorType.errorCode">
        <span translate>ERROR</span>: {{ vm.errorType.errorCode }}
      </p>
      <div class="error-description">
        <div ng-repeat="key in vm.errorType.keys">{{ key | translate }}</div>
      </div>
    </div>
    <div
      xw-alert
      display="vm.errorType"
      ng-class="(!vm.errorType.keys || vm.errorType.keys.length == 0) ? 'default-alert-height' : ''"
      on-accepted="vm.onClose()"
      cancel-continue-buttons
      ng-if="vm.errorType.continueAndCancelButtons"
    >
      <p class="white-text error-title">{{ vm.errorType.titleKey | translate }}</p>
      <p class="error-description" ng-if="vm.errorType.errorCode">
        <span translate>ERROR</span>: {{ vm.errorType.errorCode }}
      </p>
      <div class="error-description">
        <div ng-repeat="key in vm.errorType.keys">{{ key | translate }}</div>
      </div>
    </div>
    <div
      xw-alert
      display="vm.errorType"
      ng-class="(!vm.errorType.keys || vm.errorType.keys.length == 0) ? 'default-alert-height' : ''"
      on-accepted="vm.onClose()"
      cancel-try-again-buttons
      ng-if="vm.errorType.cancelTryAgainButtons"
    >
      <p class="white-text error-title">{{ vm.errorType.titleKey | translate }}</p>
      <p class="error-description" ng-if="vm.errorType.errorCode">
        <span translate>ERROR</span>: {{ vm.errorType.errorCode }}
      </p>
      <div class="error-description">
        <div ng-repeat="key in vm.errorType.keys">{{ key | translate }}</div>
      </div>
    </div>
    `,
  bindings: {
    errorType: "=",
    onClose: "&",
  },
  controllerAs: "vm",
};
