/* --------------------------------------------------------------------------------------
eula.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const eulaComponent = {
  template: require("./eula.component.html"),
  controllerAs: "vm",
  controller: class eulaCtrl {
    /* @ngInject*/
    constructor (
      appSettings,
      commonService,
      $state,
      disclaimerService,
      disclaimerTypes,
      $sce,
      $translate
    ) {
      this.appSettings = appSettings
      this.commonService = commonService
      this.disclaimerService = disclaimerService
      this.disclaimerTypes = disclaimerTypes
      this.$state = $state
      this.$sce = $sce
      this.$translate = $translate
      if(this.disclaimerService.eulaAccepted()){
        this.onAccept(false)
      }
      else{
        this.openEulaPopup()
      }
    }

    $onInit () {
      this.screenSize = this.commonService.getScreenSize()
      this.display = true
    }

    onClose () {
      this.commonService.exitApp()
    }
    onAccept (firstTimeAfterEula) {
       this.disclaimerService.acceptEula();
      this.$state.go("privacyStatement",{isFirstTimeAfterEula: firstTimeAfterEula})
    }
    async openEulaPopup () {
      this.titleKey = "EULA.TITLE"
      this.acceptLabel = "EULA.ACCEPT"
      this.appTitle="MAIN_TITLE"
      this.eulaPopup = true
      this.loadingEula = true
      this.disclaimerService
        .getEula(this.commonService.getNavigatorLanguage())
        .then(r => {
          this.eulaContent = this.renderHTML(r.data)
          this.loadingEula = false
          setTimeout(async () => {
            angular.element("#disclaimer-scroll").xrxscrollable({
              scroll: true,
              height: this.screenSize == 7 ? 350 : 474,
              width: this.screenSize == 7 ? 788 : 1006
            })
          })
          this.commonService.updateView()
        })
        .catch(err => {
          this.loadingEula = false
          this.eulaPopup = false
          //set error here
          //setError(getErrorByStatusCode(err.response.status))
        })
      document.activeElement.blur()
      this.commonService.updateView()
    }

    closeEulaPopup () {
      document.activeElement.blur()
      this.eulaPopup = false
      this.commonService.updateView()
    }

    renderHTML (html_code) {
      var decoded = angular.element("<textarea />").html(html_code).text()
      return this.$sce.trustAsHtml(decoded)
    }
  },
  bindings: {
    onOk: "&"
  }
}
