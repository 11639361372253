/* --------------------------------------------------------------------------------------
   homeActionBar.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const homeActionBarComponent = {
  template: `
    <div
      xw-action-bar
      theme-primary
      id="homeActionBar"
      label="{{vm.title}}"
      >
      <button
        ng-if="vm.homeService.togglePreview"
        xw-button
        class="xrx-action-bar-button-right xglicon xglsize-36"
        glyph="{{ vm.homeService.togglePreview ? 'view' : 'scanner'}}"
        ng-class="{ disabled: vm.disableOkStyle }"
        on-tap="vm.ok()"
        off="vm.disableOkStyle && vm.disableOk"
      >
        {{ vm.homeService.togglePreview ? 'PREVIEW' : 'SCAN' | translate }}
      </button>
      <button
        ng-if="!vm.homeService.togglePreview"
        xw-button
        class="xrx-action-bar-button-right xglicon xglsize-36"
        glyph="scanner"
        ng-class="{ disabled: vm.disableOkStyle }"
        on-tap="vm.ok()"
        off="vm.disableOkStyle && vm.disableOk"
      >
        {{ 'SCAN' | translate }}
      </button>
    </div>
  `,
  controllerAs: "vm",
  controller: class actionBarCtrl {
    /* @ngInject*/
    constructor(appSettings, homeService) {
      this.appSettings = appSettings;
      this.homeService = homeService;
    }
  },
  bindings: {
    title: "@",
    cancel: "&",
    ok: "&",
    disableOk: "<",
    disableOkStyle: "<",
  },
};
