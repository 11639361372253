/* --------------------------------------------------------------------------------------
   results-settings.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const resultsSettingsComponent = {
  template: require("./results-settings.html"),
  bindings: {
    reset: "&"
  },
  controllerAs: "vm",
  controller: class resultsSettingsController {
    /* @ngInject */
    constructor(commonService, homeService, $state) {
      this.commonService = commonService
      this.homeService = homeService
      this.$state = $state
    }

    $onInit() {
      this.screenSize = this.commonService.getScreenSize()
      this.boundingBoxHeight = this.screenSize === 7 ? 370 : 472
      this.boundingBoxWidth = this.screenSize === 7 ? 278 : 350
      this.hiddenEmails = 0

      if (this.screenSize === 10) {
        this.emailMaxInScreen = 6
      } else {
        this.emailMaxInScreen = 4
      }
    }

    refreshBoundingBox() {
      setTimeout(async () => {
        angular.element("#home-screen-scroll").xrxscrollable({
          scroll: true,
          height: this.boundingBoxHeight,
          width: this.boundingBoxWidth
        })
      })
    }

    onActionSwitch() {
      this.refreshBoundingBox()
    }

    async openResetBanner() {
      await this.reset()
      angular.element("#reset-banner").xrxbanner("call")
    }

    openRecipientsPopup() {
      this.showEmailRecipientsPopup = true
      this.commonService.updateView()

      setTimeout(async () => {
        angular.element("#email-recipients-popup").xrxscrollable("destroy")

        if (this.emailMaxInScreen < this.homeService.recipients.length) {
          if ($("#recipients-list-wrapper .scroller").length > 0) {
            $("#recipients-list").xrxscrollable("destroy")
          }
          $("#recipients-list").xrxscrollable({
            scroll: true,
            height: this.screenSize == 7 ? 312 : 424,
            width: this.screenSize == 7 ? 790 : 1008
          })
        }
      }, 300)
    }

    cancelAddNewRecipient() {
      this.showEmailRecipientsPopup = true
      this.showAddRecipientPopup = false
      this.showAddSavedRecipientPopup = false
      this.updateEmailRecipientsTableScroll()
      this.commonService.updateView()

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
    }

    cancelAddSavedRecipient() {
      this.showEmailRecipientsPopup = true
      this.showAddRecipientPopup = false
      this.showAddSavedRecipientPopup = false
      this.updateEmailRecipientsTableScroll()
      this.commonService.updateView()

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
    }

    openAddNewRecipientPopup() {
      this.showEmailRecipientsPopup = false
      this.showAddRecipientPopup = true
      this.showAddSavedRecipientPopup = false
      this.commonService.updateView()
      setTimeout(() => {
        angular.element("#email-recipient-input").getkeyboard().reveal()
      }, 500)
    }

    openAddSavedRecipientPopup() {
      this.showEmailRecipientsPopup = false
      this.showAddRecipientPopup = false
      this.showAddSavedRecipientPopup = true
      this.commonService.updateView()

      setTimeout(async () => {
        $("#saved-recipients-list").xrxscrollable({
          scroll: true,
          height: this.screenSize == 7 ? 392 : 506,
          width: this.screenSize == 7 ? 790 : 1008
        })
      }, 300)

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
    }

    closeAddNewRecipientPopup() {
      this.showEmailRecipientsPopup = true
      this.showAddRecipientPopup = false
      this.showAddSavedRecipientPopup = false
      document.activeElement.blur()
      this.updateEmailRecipientsTableScroll()
      this.commonService.updateView()

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
    }

    closeAddSavedRecipientPopup() {
      this.showEmailRecipientsPopup = true
      this.showAddRecipientPopup = false
      this.showAddSavedRecipientPopup = false
      this.updateEmailRecipientsTableScroll()
      this.commonService.updateView()

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
    }

    formattedReciptientsText() {
      let text
      this.hiddenEmails = 0
      const maxLengh = this.screenSize == 7 ? 40 : 60
      this.homeService.recipients.forEach((e) => {
        if (!text) {
          text = e
        } else if (text.length + e.length + 2 < maxLengh) {
          text += ", " + e
        } else {
          this.hiddenEmails++
        }
      })

      return text
    }

    addSavedRecipients() {
      this.showEmailRecipientsPopup = true
      this.showAddRecipientPopup = false
      this.showAddSavedRecipientPopup = false
      this.updateEmailRecipientsTableScroll()
      this.commonService.updateView()

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 500)
    }

    updateEmailRecipientsTableScroll() {
      setTimeout(async () => {
        angular.element("#email-recipients-popup").xrxscrollable("destroy")
        if (this.emailMaxInScreen < this.homeService.recipients.length) {
          if ($("#recipients-list-wrapper .scroller").length > 0) {
            $("#recipients-list").xrxscrollable("destroy")
          }
          $("#recipients-list").xrxscrollable({
            scroll: true,
            height: this.screenSize == 7 ? 312 : 424,
            width: this.screenSize == 7 ? 790 : 1008
          })
        }
        document.activeElement.blur()
      }, 300)
    }

    updateScrollComponent() {
      setTimeout(async () => {
        $("#recipients-list").xrxscrollable("destroy")

        if (this.emailMaxInScreen < this.homeService.recipients.length) {
          $("#recipients-list").xrxscrollable({
            scroll: true,
            height: this.screenSize == 7 ? 312 : 424,
            width: this.screenSize == 7 ? 790 : 1008
          })
        }

        document.activeElement.blur()
      }, 300)

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
    }

    emailRecipientsHasScroll() {
      return this.screenSize === 7
        ? this.homeService.recipients.length * 69 > 319
        : this.homeService.recipients.length * 63 > 420
    }
  }
}
