/* --------------------------------------------------------------------------------------
   file-section.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const fileSectionComponent = {
  template: require("./file-section.html"),
  controllerAs: "vm",
  bindings: {
    filename: "="
  },
  controller: class fileSectionController {
    /* @ngInject */
    constructor(commonService, scanService, homeService, $state) {
      this.commonService = commonService
      this.scanService = scanService
      this.homeService = homeService
      this.$state = $state
    }
    $onInit() {
      this.screenSize = this.commonService.getScreenSize()
      this.isEditing = false
      this.commonService.updateView()
    }
    onChangeFileName() {
      if (this.homeService.filename === "") {
        return
      }
      if (!this.isValid(this.homeService.filename)) {
        this.homeService.filename = this.homeService.filename.replace(
          /[^\w\s\.\-]/gi,
          ""
        )
        this.showDisabledBanner()
      } else if (this.homeService.filename.length > 128) {
        this.homeService.filename = this.homeService.filename.substring(0, 128)
        this.showDisabledBanner("FILE_NAME_MAX_LENGTH")
      }
    }
    onFocusFileName() {
      if (!this.isEditing) {
        this.isEditing = true
        this.homeService.filename = this.homeService.filename.substring(
          0,
          this.homeService.filename.length - 18
        )
        this.commonService.updateView()

        setTimeout(() => {
          const input = angular.element("#filename-textbox")

          input.select()
        }, 500)
      }
    }
    onBlurFileName() {
      if (this.isEditing) {
        this.isEditing = false
        if (this.homeService.filename.trim() === "") {
          this.homeService.filename = "Xerox Scan"
        }
        this.homeService.filename += ` [Date & Time].${this.homeService.fileExtensionText}`
        this.commonService.updateView()
        this.acceptKeyboard()
      }
    }
    isValid(filename) {
      return filename.match(/^[a-zA-Z0-9- _.]+$/)
    }
    showDisabledBanner(reason = "FILE_NAME_NOT_AVAILABLE") {
      if (reason === "FILE_NAME_NOT_AVAILABLE") {
        angular.element("#file-name-unavailable-banner").xrxbanner("call")
      } else if (reason === "FILE_NAME_MAX_LENGTH") {
        angular.element("#file-name-max-length-banner").xrxbanner("call")
      }
    }
    acceptKeyboard() {
      angular.element("#filename-textbox").getkeyboard().accept()
    }
  }
}
