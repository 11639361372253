/* --------------------------------------------------------------------------------------
   email-recipients-popup.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const emailRecipientsPopupComponent = {
  template: require("./email-recipients-popup.html"),
  bindings: {
    display: "=",
    openAddNewRecipientPopup: "&",
    openAddSavedRecipientPopup: "&"
  },
  controllerAs: "vm",
  controller: class emailRecipientsPopupController {
    /* @ngInject */
    constructor(commonService, homeService, $timeout) {
      this.commonService = commonService
      this.homeService = homeService
      this.$timeout = $timeout
    }

    $onInit() {
      this.screenSize = this.commonService.getScreenSize()
      this.homeService.recipientsBackup = angular.copy(
        this.homeService.recipients
      )
      this.homeService.getSavedRecipients()
      if (this.screenSize === 10) {
        this.emailMaxInScreen = 6
      } else {
        this.emailMaxInScreen = 4
      }
    }

    cancel() {
      this.homeService.recipients = angular.copy(
        this.homeService.recipientsBackup
      )
      this.commonService.updateView()
      this.display = false
      this.commonService.updateView()
    }

    ok() {
      this.display = false
      localStorage.setItem(
        "summarizer-recipients",
        JSON.stringify([
          ...this.homeService.recipients,
          ...this.homeService.savedRecipients
        ])
      )
      this.homeService.savedRecipients = [
        ...this.homeService.recipients,
        ...this.homeService.savedRecipients
      ]
      this.homeService.recipientsBackup = angular.copy(
        this.homeService.recipients
      )
      this.commonService.updateView()
    }

    removeRecipient(recipient) {
      this.homeService.recipients = this.homeService.recipients.filter(
        (r) => r !== recipient
      )
      this.updateScrollComponent()
      this.commonService.updateView()
    }

    addNewRecipient() {
      if (this.homeService.recipients.length >= 10) {
        document.activeElement.blur()
        angular.element("#maximum-recipients-banner").xrxbanner("call")
      } else {
        document.activeElement.blur()
        this.openAddNewRecipientPopup()
      }
      this.updateScrollComponent()
      this.commonService.updateView()

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
    }

    addSavedRecipient() {
      if (this.homeService.recipients.length >= 10) {
        angular.element("#maximum-recipients-banner").xrxbanner("call")
        document.activeElement.blur()
      } else {
        this.commonService.updateView()
        this.openAddSavedRecipientPopup()
      }
      this.updateScrollComponent()

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
      this.commonService.updateView()
    }

    updateScrollComponent() {
      setTimeout(async () => {
        if ($("#recipients-list-wrapper .scroller").length > 0) {
          $("#recipients-list").xrxscrollable("destroy")
        }
        if (this.emailMaxInScreen < this.homeService.recipients.length) {
          $("#recipients-list").xrxscrollable({
            scroll: true,
            height: this.screenSize == 7 ? 312 : 424,
            width: this.screenSize == 7 ? 790 : 1008
          })
        }

        document.activeElement.blur()
      }, 300)

      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
    }
  }
}
