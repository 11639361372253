/* --------------------------------------------------------------------------------------
   add-saved-recipient-popup.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const addSavedRecipientPopupComponent = {
  template: require("./add-saved-recipient-popup.html"),
  bindings: {
    display: "=",
    cancel: "&",
    onAdd: "&"
  },
  controllerAs: "vm",
  controller: class addSavedRecipientPopupController {
    /* @ngInject */
    constructor(commonService, homeService) {
      this.commonService = commonService
      this.homeService = homeService
    }

    $onInit() {
      this.screenSize = this.commonService.getScreenSize()
      this.selectedRecipients = []
      if (this.screenSize === 10) {
        this.emailMaxInScreen = 7
      } else {
        this.emailMaxInScreen = 5
      }
      this.homeService.getSavedRecipients()

      this.updateSavedRecipientsPopupTableScroll()
    }

    selectSavedRecipients(recipient) {
      const index = this.selectedRecipients.findIndex(
        (selectedRecipient) => selectedRecipient == recipient
      )
      if (index >= 0) {
        this.selectedRecipients.splice(index, 1)
      } else {
        this.selectedRecipients.push(recipient)
      }
      this.commonService.updateView()
    }

    async ok() {
      const recipients = this.homeService.uniq(
        this.selectedRecipients.concat(this.homeService.recipients)
      )

      if (recipients.length > this.homeService.maxRecipientsCount) {
        const maximumRecipientsExceeded = await this.$translate(
          "MAXIMUM_RECIPIENTS_EXCEEDED",
          { maxnum: this.homeService.maxRecipientsCount }
        )
        const uncheckPlusmaxRecipients = await this.$translate(
          "UNCHECK_PLUSMAX_RECIPIENTS",
          { plusmax: recipients.length - this.homeService.maxRecipientsCount }
        )
        this.errorType = {
          titleKey: maximumRecipientsExceeded,
          keys: [uncheckPlusmaxRecipients]
        }

        this.onErrorClose = this.errorService.getOnErrorCloseFunc(
          this.errorType
        )
        this.commonService.updateView()
        return
      }

      this.homeService.recipients = recipients
      this.onAdd()

      this.selectedRecipients = []
      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)

      angular.element('#add-saved-recipient-popup').xrxpopup('close')
    }

    cancel() {
      this.display = false
      this.commonService.updateView()

      setTimeout(async () => {
        document.activeElement.blur()
      }, 300)
    }

    onCancel() {
      this.cancel()
      setTimeout(async () => {
        document.activeElement.blur()
        this.commonService.updateView()
      }, 300)
      angular.element('#add-saved-recipient-popup').xrxpopup('close');
    }

    onBeforeClosePopup() {
      this.emailRecipient = null
      this.emailRecipientBackup = null
      this.emailRecipientRequired = false
      setTimeout(async () => {
        document.activeElement.blur()
      }, 300)
    }

    updateSavedRecipientsPopupTableScroll() {
      setTimeout(() => {
        angular.element("#saved-recipients-list").xrxscrollable({ scroll: true })
        if (this.hasScroll()) {
          angular.element("#saved-recipients-list-wrapper").removeClass("no-scroll")
          angular.element("#saved-recipients-list-wrapper").xrxscrollable({ scroll: true })
          angular.element("#saved-recipients-list-wrapper .shadow").removeClass("hide")
        } else {
          angular.element("#saved-recipients-list-wrapper").addClass("no-scroll")
          angular.element("#saved-recipients-list-wrapper").xrxscrollable("destroy")
          angular.element("#saved-recipients-list-wrapper .shadow").addClass("hide")
        }
        this.commonService.updateView()
      }, 100)
    }

    hasScroll() {
      return this.screenSize === 7
        ? this.homeService.savedRecipients.length * 69 > 308
        : this.homeService.savedRecipients.length * 63 > 420
    }
  }
}
