/* --------------------------------------------------------------------------------------
   xwPopup.directive.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class xwPopup {
    /* @ngInject*/
    constructor(appSettings) {
        this.restrict = 'A'
        this.appSettings = appSettings
        this.scope = {
            onOpen: '&',
            onClose: '&',
            onCancel: '&',
            beforeCancel: '&',
            actionBar: '<',
            display: '<',
            disableOk: '<',
            themePrimary: '<'
        }
    }
    link(scope, elem, attrs) {
        let buttons = []

        if (attrs.hasOwnProperty('closeButton')) {
            buttons.push({
                click: () => elem.first().xrxpopup('close')
            })
        }

        let options = {
            width: attrs.width,
            height: attrs.height,
            theme: attrs.theme ? attrs.theme : this.appSettings.primaryColor,
            themePrimary: scope.themePrimary,
            title: attrs.title,
            actionBar: scope.actionBar,
            customActionBar: attrs.customActionBar,
            focusControl: attrs.focusControl,
            open: (event, ui) => scope.onOpen(),
            close: (event, ui) => scope.onClose(),
            cancel: (event, ui) => scope.onCancel(),
            beforeCancel: (event, ui) => scope.beforeCancel()
        }

        if (buttons.length) {
            options.buttons = buttons
        }
        elem.first().xrxpopup(options)

        if (attrs.closeButtonLabel) {
            elem.first().xrxpopup('selectClose').xrxbutton('option', 'label', attrs.closeButtonLabel)
        }

        scope.$watch('display', val => {
            const action = val? 'open' : 'close'   
            setTimeout(()=>elem.first().xrxpopup(action))
        })
        scope.$watch('disableOk', (val) => {
            if (typeof val !== "undefined")
                elem.first().xrxpopup('selectClose').xrxbutton({disabled: val})
        })
        scope.$on('$destroy', () => {
            elem.first().remove()
        })

    }
    static directiveFactory(appSettings) {
        xwPopup.instance = new xwPopup(appSettings)
        return xwPopup.instance
    }
}
