/* --------------------------------------------------------------------------------------
   app.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/
import angular from "angular";
import uiRouter from "@uirouter/angularjs";
import "angular-translate";
import "angular-translate-loader-static-files";

require("angular-moment");
require("ng-mask");
require("angular-sanitize");
require("angular-cookies");

import { appCommonModule } from "./common/common";
import { appXrxWrappersModule } from "./xrxWrappers/xrxWrappers";
import { appHomeModule } from "./home/home.module";

import { xrxDependencies } from "./xrxDependencies/xrxDependencies.module";

import { landingComponent } from "./components/landing.component";
import { privacyStatementComponent } from "./components/disclaimers/privacyStatement.component";

import { xeroxSSOComponent } from "./components/xeroxSSO.component";
import { xeroxSSORedirectComponent } from "./components/xeroxSSORedirect.component";


import { scanService } from "./services/scan.service";
import { disclaimerService } from "./services/disclaimerService";
import { eCommerceService } from "./services/ecommerce.service";

import { appSettings } from "./constants/appSettings.const";
import { xeroxEIPParameters } from "./constants/xeroxEIPParameters.const";
import { errorTypes } from "./constants/errorTypes.const";
import { general } from "./constants/general.const";
import { disclaimerPopupComponent } from "./components/disclaimers/disclaimerPopup.component";
import { disclaimerTypes } from "./constants/disclaimerTypes.const";
import { eulaComponent } from "./components/disclaimers/eula.component";
import { errorComponent } from "./components/error.component";
export default angular
  .module("app", [
    "ui.router",
    "angularMoment",
    "pascalprecht.translate",
    "ngMask",
    "ngSanitize",
    "ngCookies",
    "app.common",
    "app.home",
    "app.xrxWrappers",
    "app.xrxDependencies",
  ])
  .component("landing", landingComponent)
  .component("privacyStatement", privacyStatementComponent)
  .component("eula", eulaComponent)
  .component("disclaimerPopup", disclaimerPopupComponent)
  .component("xeroxSSO", xeroxSSOComponent)
  .component("xeroxSSORedirect", xeroxSSORedirectComponent)
  .component("error", errorComponent)
  .service("scanService", scanService)
  .service("eCommerceService", eCommerceService)
  .service("disclaimerService", disclaimerService)
  .constant("appSettings", appSettings)
  .constant("xeroxEIPParameters", xeroxEIPParameters)
  .constant("errorTypes", errorTypes)
  .constant("general", general)
  .constant("disclaimerTypes", disclaimerTypes)

  .config(($stateProvider, $urlRouterProvider, $locationProvider) => {
    const setLanguageFunction = (
      $translate,
      shellDataBagService,
      commonService
    ) => {
      return $translate.onReady().then(() => {
        shellDataBagService.setData();
        $translate.use(commonService.getNavigatorLanguage());
        // $translate.use("de");
      });
    };
    $locationProvider.html5Mode(true);
    $stateProvider
      .state("main", {
        component: "main",
        resolve: {
          translateReady: setLanguageFunction,
        },
      })
      .state("eula", {
        component: "eula",
        resolve: {
          translateReady: setLanguageFunction,
        },
      })
      .state("privacyStatement", {
        component: "privacyStatement",
        params: {
          isFirstTimeAfterEula: false
      },
        resolve: {
          translateReady: setLanguageFunction,
        }
      })
      .state("landing", {
        component: "landing",
        url: "/",
        resolve: {
          translateReady: setLanguageFunction,
        },
      })
      .state("empty", {
        component: "empty",
      })
      .state("error",{
        component: "error",
        params:{
        errorType: null,
        onClose: null
        }
      })
      .state("home", {
        component: "home",
      })
      .state("xeroxSSO", {
        url: "/xeroxsso",
        component: "xeroxSSO",
      })
      .state("xeroxSSORedirect", {
        component: "xeroxSSORedirect",
        params: {
          operation: null,
        },
      });

    $urlRouterProvider.otherwise("/");
  })
  .config(($translateProvider) => {
    /* @ngInject*/
    $translateProvider.useStaticFilesLoader({
      files: [
        {
          prefix: "./i18n/locale-",
          suffix: ".json",
        },
      ],
    });
    $translateProvider.fallbackLanguage("en");
    $translateProvider.useSanitizeValueStrategy("sanitizeParameters");
  })
  .run(sessionDataService => {
    sessionDataService.setDeviceIp();
  })
  .filter("trusted", [
    "$sce",
    function ($sce) {
      // ES6 this
      return function (url) {
        return $sce.trustAsResourceUrl(url);
      };
    },
  ]);
