/* --------------------------------------------------------------------------------------
  home.service.js
  Copyright © 2024 Xerox Corporation. All Rights Reserved.

  Copyright protection claimed includes all forms and matters of copyrightable material
  and information now allowed by statutory or judicial law or hereinafter granted,
  including without limitation, material generated from the software programs which
  are displayed on the screen such as icons, screen display looks, etc.
  -------------------------------------------------------------------------------------
*/

export class homeService {
  /* @ngInject*/
  constructor(
    xeroxEIPParameters,
    languagesService,
    deviceInfoService,
    apiClientService,
    commonService,
    scanService,
    printService,
    jobStatusService,
    persistService,
    $translate,
    $filter,
    moment
  ) {
    this.xeroxEIPParameters = xeroxEIPParameters
    this.languagesService = languagesService
    this.deviceInfoService = deviceInfoService
    this.apiClientService = apiClientService
    this.commonService = commonService
    this.scanService = scanService
    this.printService = printService
    this.jobStatusService = jobStatusService
    this.persistService = persistService
    this.$translate = $translate
    this.$filter = $filter
    this.moment = moment

    this.togglePreview = true
    this.printResults = true
    this.emailResults = false
    this.includesOriginal = false
    this.summarizationLengthOptions = [
      {
        key: "5%",
        translationKey: "5%",
        label: "5%",
        value: 5
      },
      {
        key: "10%",
        translationKey: "10%",
        label: "10%",
        value: 10
      },
      {
        key: "15%",
        translationKey: "15%",
        label: "15%",
        value: 15
      },
      {
        key: "20%",
        translationKey: "20%",
        label: "20%",
        value: 20
      },
      {
        key: "25%",
        translationKey: "25%",
        label: "25%",
        value: 25
      },
      {
        key: "30%",
        translationKey: "30%",
        label: "30%",
        value: 30
      },
      {
        key: "40%",
        translationKey: "40%",
        label: "40%",
        value: 40
      },
      {
        key: "50%",
        translationKey: "50%",
        label: "50%",
        value: 50
      },
      {
        key: "60%",
        translationKey: "60%",
        label: "60%",
        value: 60
      },
      {
        key: "75%",
        translationKey: "75%",
        label: "75%",
        value: 75
      },
      {
        key: "90%",
        translationKey: "90%",
        label: "90%",
        value: 90
      }
    ]
    this.summarizationLengthText = "10%"
    this.summarizationLengthIndex = 1
    this.summarizationLengthValue = 10

    this.commonDomainOptions = [
      {
        key: "GENERAL",
        translationKey: "GENERAL",
        label: "GENERAL",
        value: "GENERAL"
      },
      {
        key: "BUSINESS",
        translationKey: "BUSINESS",
        label: "BUSINESS",
        value: "BUSINESS"
      },
      {
        key: "EDUCATION",
        translationKey: "EDUCATION",
        label: "EDUCATION",
        value: "EDUCATION"
      },
      {
        key: "HEALTHCARE",
        translationKey: "HEALTHCARE",
        label: "HEALTHCARE",
        value: "HEALTHCARE"
      },
      {
        key: "INSURANCE",
        translationKey: "INSURANCE",
        label: "INSURANCE",
        value: "INSURANCE"
      },
      {
        key: "LEGAL",
        translationKey: "LEGAL",
        label: "LEGAL",
        value: "LEGAL"
      },
      {
        key: "SCIENCE",
        translationKey: "SCIENCE",
        label: "SCIENCE",
        value: "SCIENCE"
      }
    ]

    this.additionalDomainOptions = [
      {
        key: "ACCOUNTING",
        translationKey: "ACCOUNTING",
        label: "ACCOUNTING",
        value: "ACCOUNTING"
      },
      {
        key: "AGRICULTURE",
        translationKey: "AGRICULTURE",
        label: "AGRICULTURE",
        value: "AGRICULTURE"
      },
      {
        key: "ART",
        translationKey: "ART",
        label: "ART",
        value: "ART"
      },
      {
        key: "AUTOMOTIVE",
        translationKey: "AUTOMOTIVE",
        label: "AUTOMOTIVE",
        value: "AUTOMOTIVE"
      },
      {
        key: "BEAUTY",
        translationKey: "BEAUTY",
        label: "BEAUTY",
        value: "BEAUTY"
      },
      {
        key: "CONSTRUCTION",
        translationKey: "CONSTRUCTION",
        label: "CONSTRUCTION",
        value: "CONSTRUCTION"
      },
      {
        key: "CULTURE",
        translationKey: "CULTURE",
        label: "CULTURE",
        value: "CULTURE"
      },
      {
        key: "DEMOGRAPHICS",
        translationKey: "DEMOGRAPHICS",
        label: "DEMOGRAPHICS",
        value: "DEMOGRAPHICS"
      },
      {
        key: "ECONOMICS",
        translationKey: "ECONOMICS",
        label: "ECONOMICS",
        value: "ECONOMICS"
      },
      {
        key: "ELECTRONICS",
        translationKey: "ELECTRONICS",
        label: "ELECTRONICS",
        value: "ELECTRONICS"
      },
      {
        key: "ENERGY",
        translationKey: "ENERGY",
        label: "ENERGY",
        value: "ENERGY"
      },
      {
        key: "ENVIRONMENT",
        translationKey: "ENVIRONMENT",
        label: "ENVIRONMENT",
        value: "ENVIRONMENT"
      },
      {
        key: "EUROPEAN_UNION",
        translationKey: "EUROPEAN_UNION",
        label: "EUROPEAN_UNION",
        value: "EUROPEAN_UNION"
      },
      {
        key: "FINANCE",
        translationKey: "FINANCE",
        label: "FINANCE",
        value: "FINANCE"
      },
      {
        key: "FISHERIES",
        translationKey: "FISHERIES",
        label: "FISHERIES",
        value: "FISHERIES"
      },
      {
        key: "FOODS",
        translationKey: "FOODS",
        label: "FOODS",
        value: "FOODS"
      },
      {
        key: "FORESTRY",
        translationKey: "FORESTRY",
        label: "FORESTRY",
        value: "FORESTRY"
      },
      {
        key: "GARDENING",
        translationKey: "GARDENING",
        label: "GARDENING",
        value: "GARDENING"
      },
      {
        key: "GEOGRAPHY",
        translationKey: "GEOGRAPHY",
        label: "GEOGRAPHY",
        value: "GEOGRAPHY"
      },
      {
        key: "HEALTHCARE",
        translationKey: "HEALTHCARE",
        label: "HEALTHCARE",
        value: "HEALTHCARE"
      },
      {
        key: "HUMAN_RESOURCES",
        translationKey: "HUMAN_RESOURCES",
        label: "HUMAN_RESOURCES",
        value: "HUMAN_RESOURCES"
      },
      {
        key: "INDUSTRIES",
        translationKey: "INDUSTRIES",
        label: "INDUSTRIES",
        value: "INDUSTRIES"
      },
      {
        key: "INTELLECTUAL_PROPERTY",
        translationKey: "INTELLECTUAL_PROPERTY",
        label: "INTELLECTUAL_PROPERTY",
        value: "INTELLECTUAL_PROPERTY"
      },
      {
        key: "INTERNATIONAL_ORGANIZATIONS",
        translationKey: "INTERNATIONAL_ORGANIZATIONS",
        label: "INTERNATIONAL_ORGANIZATIONS",
        value: "INTERNATIONAL_ORGANIZATIONS"
      },
      {
        key: "INTERNATIONAL_RELATIONS",
        translationKey: "INTERNATIONAL_RELATIONS",
        label: "INTERNATIONAL_RELATIONS",
        value: "INTERNATIONAL_RELATIONS"
      },
      {
        key: "INVESTMENTS",
        translationKey: "INVESTMENTS",
        label: "INVESTMENTS",
        value: "INVESTMENTS"
      },
      {
        key: "IT",
        translationKey: "IT",
        label: "IT",
        value: "IT"
      },
      {
        key: "Literature",
        translationKey: "LITERATURE",
        label: "LITERATURE",
        value: "LITERATURE"
      },
      {
        key: "MANAGEMENT",
        translationKey: "MANAGEMENT",
        label: "MANAGEMENT",
        value: "MANAGEMENT"
      },
      {
        key: "MARKETING",
        translationKey: "MARKETING",
        label: "MARKETING",
        value: "MARKETING"
      },
      {
        key: "PARLIAMENT",
        translationKey: "PARLIAMENT",
        label: "PARLIAMENT",
        value: "PARLIAMENT"
      },
      {
        key: "PETS",
        translationKey: "PETS",
        label: "PETS",
        value: "PETS"
      },
      {
        key: "POLITICS",
        translationKey: "POLITICS",
        label: "POLITICS",
        value: "POLITICS"
      },
      {
        key: "PRODUCTION",
        translationKey: "PRODUCTION",
        label: "PRODUCTION",
        value: "PRODUCTION"
      },
      {
        key: "RELIGION",
        translationKey: "RELIGION",
        label: "RELIGION",
        value: "RELIGION"
      },
      {
        key: "SOCIAL_ISSUES",
        translationKey: "SOCIAL_ISSUES",
        label: "SOCIAL_ISSUES",
        value: "SOCIAL_ISSUES"
      },
      {
        key: "SPORT",
        translationKey: "SPORT",
        label: "SPORT",
        value: "SPORT"
      },
      {
        key: "TAXES",
        translationKey: "TAXES",
        label: "TAXES",
        value: "TAXES"
      },
      {
        key: "TECHNOLOGY",
        translationKey: "TECHNOLOGY",
        label: "TECHNOLOGY",
        value: "TECHNOLOGY"
      },
      {
        key: "TRADE",
        translationKey: "TRADE",
        label: "TRADE",
        value: "TRADE"
      },
      {
        key: "TRANSPORTATION_AND_CARGO",
        translationKey: "TRANSPORTATION_AND_CARGO",
        label: "TRANSPORTATION_AND_CARGO",
        value: "TRANSPORTATION_AND_CARGO"
      },
      {
        key: "TRAVEL",
        translationKey: "TRAVEL",
        label: "TRAVEL",
        value: "TRAVEL"
      },
      {
        key: "WEATHER",
        translationKey: "WEATHER",
        label: "WEATHER",
        value: "WEATHER"
      }
    ]
    this.summarizationDomainIndex = 1
    this.summarizationDomainText = "GENERAL"
    this.summarizationTypeText = "EXTRACTIVE"
    this.summarizationTypeExtractive = true

    this.recipients = []
    this.recipientsBackup = []

    this.isEmailSettingsChanged = false

    this.fileExtension = "DOCX"
    this.fileExtensionText = "docx"
    this.fileExtensionOptions = [
      {
        key: "DOCX",
        translationKey: ".docx",
        label: "docx",
        value: "docx"
      },
      {
        key: "PDF",
        translationKey: ".pdf",
        label: "pdf",
        value: "pdf"
      }
    ]

    this.filename = "Xerox Scan [Date & Time].docx"
  }

  async setDefaults() {
    this.togglePreview = true
    this.printResults = true
    this.emailResults = false
    this.includesOriginal = false
    this.summarizationLengthText = "10%"
    this.summarizationLengthIndex = 1
    this.summarizationDomainIndex = 1
    this.summarizationDomainText = "GENERAL"

    this.filename = "Xerox Scan [Date & Time].docx"
    this.recipients = []
    this.recipientsBackup = []

    this.summarizationTypeText = "EXTRACTIVE"
    this.summarizationTypeExtractive = true
  }

  async scan(scanProfileId) {
    try {
      this.scanProfileId = scanProfileId

      const a4SizePref = await this.deviceInfoService.getPaperPreferenceA4()

      const fileName = this.filename.replace(
        "[Date & Time]",
        `${this.moment().format("YYYY-MM-DD hh.mm.ss A")}`
      )

      const languageCode = this.commonService.getNavigatorLanguage()

      const jobId = await this.scanService.scan({
        token: this.scanProfileId,
        duplex: this.scanService.twoSidedScan,
        colorMode: "Grayscale",
        resolution: "300x300",
        orientation: this.scanService.originalOrientation,
        originalSize: this.scanService.originalSize,
        originalType: this.scanService.originalType,
        filename: fileName,
        darkness: 0,
        preview: this.togglePreview,
        summaryPercent:
          this.summarizationLengthOptions[this.summarizationLengthIndex].value,
        printResults: this.printResults,
        includeOriginal: this.includesOriginal,
        summaryType: this.summarizationTypeExtractive ? "extract" : "abstract",
        summaryFormat: this.fileExtension,
        domain: this.summarizationDomainText,
        emailTarget: this.emailResults ? this.recipients.join(";") : "",
        printsize: a4SizePref ? "A4" : "letter",
        language: languageCode == "pt" ? "pt-br" : languageCode
      })
      await this.jobStatusService.getScanFinishedStatus(jobId)
    } catch (error) {
      console.log(error)
      throw error
    }
  }


  async getSummaryPreviewData(scanProfileId) {
    try {
      const response = await this.apiClientService.get(
        `/scan/preview/${scanProfileId}`
      )
      this.summaryResponse = response;
     this.setSummaryDetails();
      return response
    } catch (error) {
      throw error
    }
  }

  setSummaryDetails(){
    const summaryLength = this.summarizationTypeExtractive
    ? this.summarizationLengthValue
    : 100;

      this.summaryData =  this.summaryResponse.length > 0 ? this.summaryResponse.sort(
        (a, b) => b.weight - a.weight
      ): this.summaryResponse;
      this.sentenceCount = Math.floor(
        (this.summaryData.length / 100) * summaryLength
      )
      //if sentence count is under 1, ceil to 1.
      if(this.sentenceCount < 1){
        this.sentenceCount = 1;
      }
      this.wordsCountTotal = this.summaryData
      .slice(0, this.sentenceCount)
      .map((summary) => summary.words)
      .reduce((sum, count) => sum + count, 0)
    this.charactersCountTotal = this.summaryData
      .slice(0, this.sentenceCount)
      .map((summary) => summary.characters)
      .reduce((sum, count) => sum + count, 0)
    this.sentences = this.summaryData
      .slice(0, this.sentenceCount)
      .sort((a, b) => b.id - a.id)
      .map((summary) => summary.text)
      .join(" ")
    }

  sendSummarizationEmail() {
    return this.apiClientService.put(`/scan/${this.scanProfileId}`, {
      text: this.sentences
    })
  }

  async print(blobUrl) {
    try {
      const printOptions = {
        copies: 1,
        sides: "OneSided",
        staple: "None",
        color: "Auto"
      }
      this.isPrinting = true
      const fileName = this.filename.replace(
        "[Date & Time]",
        `${this.moment().format("YYYY-MM-DD hh.mm.ss A")}`
      )
      this.printJobId = await this.printService.SubmitPrint(
        blobUrl,
        fileName,
        printOptions
      )
      const result = await this.jobStatusService.getPrintFinishedStatus(
        this.printJobId
      )
      if (
        result == "JobCanceledByUser" ||
        result == "CancelByUser" ||
        result == "None"
      ) {
        throw "JobCanceledByUser"
      } else if (result === "NetworkControllerError") {
        throw "NetworkControllerError"
      }
    } catch (error) {
      throw error
    }
  }

  getSavedRecipients() {
    this.savedRecipients = this.uniq(
      this.persistService.getObject("summarizer-recipients") || []
    )
  }

  saveRecipients() {
    this.savedRecipients = this.uniq(
      this.recipients.concat(this.savedRecipients)
    ).slice(0, 10)
    this.persistService.setObject("summarizer-recipients", this.savedRecipients)
  }

  addNew(email) {
    this.recipients.splice(0, -1, email)
    this.recipients = this.uniq(this.recipients)
  }

  removeAt(index) {
    this.recipients.splice(index, 1)
  }

  uniq(recipients) {
    const uniqRecipients = []
    for (let recipient of recipients) {
      if (uniqRecipients.findIndex((r) => r == recipient) < 0) {
        uniqRecipients.push(recipient)
      }
    }
    return uniqRecipients
  }
}
