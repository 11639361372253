/* --------------------------------------------------------------------------------------
   xwAlert.directive.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

/*
Events:

Accepted will be triggered if an acceptance button is pressed
Cancel will be triggered if a cancel button is pressed
Close will be triggered when an alert is being closed programmatically
*/

/*eslint semi: ["error", "never"]*/
 export class xwAlert {
  /*@ngInject*/
  constructor ($filter) {
    this.restrict = 'A'
    this.$filter = $filter
    this.scope = {
      display: '<',
      onClose: '&',
      onAccepted: '&',
      onCancel: '&',
      buttons: '<'
    }
  }
  link(scope, elem, attrs) {
    if (!scope.buttons)
      scope.buttons = []
    if (attrs.hasOwnProperty('closeButton'))
      scope.buttons.push({
        alertIcon: 'glyphicon-close',
        text: this.$filter('translate')('CLOSE'),
        click: () => elem.first().xrxalert('close')
      })
    if (attrs.hasOwnProperty('continueButton'))
      scope.buttons.push({
        alertIcon: 'glyphicon-navigate-right',
        text: this.$filter('translate')('CONTINUE'),
        click: () => {
          scope.accepted = true
          elem.first().xrxalert('close')
        }
      })
    if (attrs.hasOwnProperty('cancelButton'))
      scope.buttons.push({
        alertIcon: 'glyphicon-close',
        text: this.$filter('translate')('CANCEL'),
        click: () => {
          scope.cancel = true
          elem.first().xrxalert('close')
        }
      })
    if (attrs.hasOwnProperty('yesNoButtons')) {
      scope.buttons.push({
        text:  this.$filter('translate')('NO'),
        click: () => elem.first().xrxalert('close')
      })
      scope.buttons.push({
        text: this.$filter('translate')('YES'),
        click: () => {
          scope.accepted = true
          elem.first().xrxalert('close')
        }
      })
    }
    if (attrs.hasOwnProperty('cancelContinueButtons')) {
      scope.buttons.push({
        text:  this.$filter('translate')('CANCEL'),
        alertIcon: 'glyphicon-close',
        click: () => elem.first().xrxalert('close')
      })
      scope.buttons.push({
        text: this.$filter('translate')('CONTINUE'),
        alertIcon: 'glyphicon-exit',
        click: () => {
          scope.accepted = true
          elem.first().xrxalert('close')
        }
      })
    }
    const options = {
        buttons: scope.buttons,
        title: attrs.title,
        open: () => this.undetentButtons(),
        close: () => {
          this.undetentButtons()
          if (scope.accepted) {
            scope.accepted = false
            scope.onAccepted()
          }
          else if (scope.cancel) {
            scope.cancel = false
            scope.onCancel()
          }
          else
            scope.onClose()
        }
    }

    elem.first().xrxalert(options)
    //listen to attribute change to open/close
    scope.$watch('display', val => {
      if (val)
        elem.first().xrxalert('open')
      else
        elem.first().xrxalert('close')
    });

    scope.$on('$destroy', () => {
      elem.first().xrxalert('destroy').remove()      
    })    
  }
  undetentButtons() {
    const controls = angular.element('.ui-button.ui-state-focus')
    controls.removeClass('ui-state-focus')
  }
  static directiveFactory($filter) {
    xwAlert.instance = new xwAlert($filter)
    return xwAlert.instance
  }
}
