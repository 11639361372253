/* --------------------------------------------------------------------------------------
   summarization-types-popup.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

import angular from "angular"

export const summarizationTypesPopupComponent = {
  template: require("./summarization-types-popup.html"),
  bindings: {
    display: "="
  },
  controllerAs: "vm",
  controller: class summarizationTypesPopupController {
    /* @ngInject */
    constructor(commonService, homeService, $translate, $state) {
      this.commonService = commonService
      this.homeService = homeService
      this.$translate = $translate
      this.$state = $state
    }

    async $onInit() {
      this.screenSize = this.commonService.getScreenSize()
      this.boundingBoxHeight = this.screenSize === 7 ? 370 : 472
      this.boundingBoxWidth = this.screenSize === 7 ? 278 : 350
      this.summarizationTypeText = angular.copy(this.homeService.summarizationTypeText)
      this.summarizationTypeExtractive = angular.copy(this.homeService.summarizationTypeExtractive)
    }

    onOpen() {
      this.updateSegmented(this.summarizationTypeExtractive)
    }

    refreshBoundingBox() {
      setTimeout(async () => {
        angular.element("#home-screen-scroll").xrxscrollable({
          scroll: true,
          height: this.boundingBoxHeight,
          width: this.boundingBoxWidth
        })
      })
    }

    onSelectSummarizationTypeOption(isExtractive) {
      if (!isExtractive) {
        this.summarizationTypeText = "ABSTRACTIVE"
        this.summarizationTypeExtractive = false
        this.commonService.updateView()
      } else {
        this.summarizationTypeText = "EXTRACTIVE"
        this.summarizationTypeExtractive = true
        this.commonService.updateView()
      }

      this.updateSegmented()
    }

    close() {
      if (angular.element("#summarization-types-popup"))
        angular.element("#summarization-types-popup").xrxpopup("close")
    }

    onClose() {
      this.display = false
      this.commonService.updateView()
    }

    onCancel() {
      this.summarizationTypeText = angular.copy(this.homeService.summarizationTypeText)
      this.summarizationTypeExtractive = angular.copy(this.homeService.summarizationTypeExtractive)

      this.display = false
      this.commonService.updateView()
    }

    onContinue() {
      this.homeService.summarizationTypeText = angular.copy(this.summarizationTypeText)
      this.homeService.summarizationTypeExtractive = angular.copy(this.summarizationTypeExtractive)

      this.close()
      this.refreshBoundingBox()
      this.commonService.updateView()
    }

    updateSegmented() {
      angular
        .element("#toggle-summarization-type-menu li")
        .removeClass("ui-state-focus ui-state-selected")
      angular
        .element("#toggle-summarization-type-menu li")
        .eq(this.summarizationTypeExtractive ? 0 : 1)
        .addClass("ui-state-selected")
    }
  }
}
